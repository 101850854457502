import Robinhood from "assets/images/logos/robinhood.png";
import Tradier from "assets/images/logos/tradier.png";
import TradeStation from "assets/images/logos/trade-station.png";
import Alpaca from "assets/images/logos/alpaca.png";
import Webull from "assets/images/logos/webull.png";
import CharlesSchwab from "assets/images/logos/charles-schwab.jpeg";
import ETrade from "assets/images/logos/e-trade.webp";

const brokers = [
  // {
  //   id: 1,
  //   brokerName: "Robinhood",
  //   brokerImg: Robinhood,
  //   slug: "ROBINHOOD",
  // },
  {
    id: 2,
    brokerName: "Webull",
    brokerImg: Webull,
    slug: "WEBULL",
  },
  {
    id: 3,
    brokerName: "Alpaca",
    brokerImg: Alpaca,
    slug: "ALPACA",
  },
  {
    id: 4,
    brokerName: "Alpaca Paper",
    brokerImg: Alpaca,
    slug: "ALPACA-PAPER",
  },
 
  {
    id: 5,
    brokerName: "Tradestation",
    slug: "TRADESTATION",
    brokerImg: TradeStation,
  },
  // {
  //   id: 4,
  //   brokerName: "Tradestation Simulated",
  //   slug: "TRADESTATION_SIM",
  //   brokerImg: TradeStation,
  // },
  {
    id: 6,
    brokerName: "Tradier",
    slug: "TRADIER",
    brokerImg: Tradier,
  },
  {
    id: 7,
    brokerName: "Charles Schwab",
    slug: "SCHWAB",
    brokerImg: CharlesSchwab,
  },
  {
    id: 8,
    brokerName: "E-Trade",
    slug: "ETRADE",
    brokerImg: ETrade,
  }
];

export default brokers;
