/* INVESTOR FACE ICONS */
import WarrenBuffet from "assets/images/investors/warren-buffet.png";
import JackBogle from "assets/images/investors/jack-bogle.png";
import Benjamin from "assets/images/investors/benjamin.png";

import WarrenBuffetFull from "assets/images/investors/warren-buffet-full.png";
import PeterLynchFull from "assets/images/investors/peter-lynch-full.png";
import JackBogleFull from "assets/images/investors/jack-bogle-full.png";

/* ETF ICONS */
import ETF01 from "assets/images/etf-icons/etf-01.png";
import ETF02 from "assets/images/etf-icons/etf-02.png";
import ETF03 from "assets/images/etf-icons/etf-03.png";
import ETF04 from "assets/images/etf-icons/etf-04.png";
import ETF05 from "assets/images/etf-icons/etf-05.png";
import ETF06 from "assets/images/etf-icons/etf-06.png";
import ETF07 from "assets/images/etf-icons/etf-07.png";
import ETF08 from "assets/images/etf-icons/etf-08.png";

import ETF01Detail from "assets/images/etf-icons/etf-01-detail.png";
import ETF02Detail from "assets/images/etf-icons/etf-02-detail.png";
import ETF03Detail from "assets/images/etf-icons/etf-03-detail.png";
import ETF04Detail from "assets/images/etf-icons/etf-04-detail.png";
import ETF05Detail from "assets/images/etf-icons/etf-05-detail.png";
import ETF06Detail from "assets/images/etf-icons/etf-06-detail.png";
import ETF07Detail from "assets/images/etf-icons/etf-07-detail.png";
import ETF08Detail from "assets/images/etf-icons/etf-08-detail.png";

/* BROKER */
import Alpaca from "assets/images/logos/alpaca.png";

const stocksList = [
  {
    id: 1,
    image: WarrenBuffet,
    name: "Warren Buffet",
    strategyName: "90 / 10 Strategy",
    riskScore: 3.6,
    returnPercentage: 13.24,
    type: "investor",
    underlying: "_BUFFETT",
    about:
      "This is some text about the strategy overview that would explain what it is, where it was derived from and who it’s for...",
    investorDetails: {
      image: WarrenBuffetFull,
      heading: "    Who is Warren Buffet",
      list: [
        "Averaged 20% per year for over 50 years, making him one of the best investors in history",
        "Says the average person should just follow the 90/10 portfolio to reach wealth",
        "Pledged to donate 99% of his wealth",
      ],
      composition: {
        initial: 90,
        secondary: 10,
        tertiary: 0,
        details: ` 10% is invested into BILLS`,
        others: ` The Bloomberg 3-12 Month T-Bill ETF (BILLS) helps
                              you invest in short-term bonds backed by the U.S.
                              government.`,
      },
    },
  },
  // {
  //   id: 2,
  //   image: RayDalio,
  //   name: "Ray Dalio",
  //   strategyName: "All Weather Strategy",
  //   riskScore: 3.6,
  //   returnPercentage: 9.7,
  //   underlying: "_RAY",
  //   about:
  //     "This is some text about the strategy overview that would explain what it is, where it was derived from and who it’s for...",
  //   type: "investor",
  //   investorDetails: {
  //     image: RayDalioFull,
  //     heading: " Who is Ray Dalio",
  //     list: [
  //       " Averaged 20% per year for over 50 years, making him one of the best investors in history",
  //       " Says the average person should just follow the 90/10 portfolio to reach wealth",
  //       "Pledged to donate 99% of his wealth",
  //     ],
  //     composition: {
  //       initial: 33.33,
  //       secondary: 33.33,
  //       tertiary: 33.33,
  //       others: ` The Bloomberg 3-12 Month T-Bill ETF (BILLS) helps
  //                             you invest in short-term bonds backed by the U.S.
  //                             government.`,
  //     },
  //   },
  // },

  {
    id: 4,
    image: JackBogle,
    name: "Jack Bogle",
    strategyName: "3 Fund Strategy",
    riskScore: 3.0,
    returnPercentage: 8.13,
    type: "investor",
    underlying: "_BOGLE",
    about:
      "This is some text about the strategy overview that would explain what it is, where it was derived from and who it’s for...",
    investorDetails: {
      image: JackBogleFull,
      heading: " Who is Jack Bogle",
      list: [
        " Averaged 20% per year for over 50 years, making him one of the best investors in history",
        " Says the average person should just follow the 90/10 portfolio to reach wealth",
        "Pledged to donate 99% of his wealth",
      ],
      composition: {
        initial: 33.33,
        secondary: 33.33,
        tertiary: 33.33,
        details: ` 33.3% is invested into BILLS`,
        others: ` The Bloomberg 3-12 Month T-Bill ETF (BILLS) helps
                              you invest in short-term bonds backed by the U.S.
                              government.`,
      },
    },
  },
  {
    id: 3,
    image: Benjamin,
    name: "Benjamin Graham",
    strategyName: "Defensive Investor",
    riskScore: 3.2,
    returnPercentage: 8.24,
    underlying: "_GRAHAM",
    about:
      "This is some text about the strategy overview that would explain what it is, where it was derived from and who it’s for...",
    type: "investor",
    investorDetails: {
      image: PeterLynchFull,
      heading: "Who is Benjamin Graham",
      list: [
        " Averaged 20% per year for over 50 years, making him one of the best investors in history",
        " Says the average person should just follow the 90/10 portfolio to reach wealth",
        "Pledged to donate 99% of his wealth",
      ],
      composition: {
        initial: 33.33,
        secondary: 33.33,
        tertiary: 33.33,
        others: ` The Bloomberg 3-12 Month T-Bill ETF (BILLS) helps
        you invest in short-term bonds backed by the U.S.
        government.`,
      },
    },
  },
  {
    id: 5,
    image: ETF01,
    name: "Ticker: VOO",
    strategyName: "500 Largest Companies",
    riskScore: 3.6,
    returnPercentage: 10.26,
    underlying: "VOO",
    type: "etf",
    investorDetails: {
      image: ETF01Detail,
      heading: "What is VOO?",
      list: [
        ` This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion`,
        `It was started in 2004 and is run by an extremely credible asset
                manager, SPDR`,
        ` It has a 0.40% expense ratio, which is average`,
      ],
    },
  },
  {
    id: 6,
    image: ETF02,
    name: "Ticker: QQQ",
    strategyName: "Big tech companies",
    riskScore: 3.75,
    returnPercentage: 13.65,
    type: "etf",
    underlying: "QQQ",
    investorDetails: {
      image: ETF02Detail,
      heading: "What is QQQ?",
      list: [
        `   The Nasdaq ETF (QQQ) is one of the three major ways people track
                the stock market`,
        `  It’s a way to invest in the largest tech companies in the United
                States`,
        ` It’s often a bit more volatile than other options like the S&P
                500 and Dow Jones`,
      ],
    },
  },
  {
    id: 7,
    image: ETF03,
    name: "Ticker: SCHD",
    strategyName: "Dividend investing",
    riskScore: 3.7,
    returnPercentage: 13.34,
    type: "etf",
    underlying: "SCHD",
    investorDetails: {
      image: ETF03Detail,
      heading: "What is SCHD?",
      list: [
        `  This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion`,
        `   It was started in 2004 and is run by an extremely credible asset
                manager, SPDR`,
        `   It has a 0.40% expense ratio, which is average`,
      ],
    },
  },
  {
    id: 8,
    image: ETF04,
    name: "Ticker: GLD",
    strategyName: "Invest in Gold",
    riskScore: 3.6,
    returnPercentage: 10.26,
    type: "etf",
    underlying: "GLD",
    investorDetails: {
      image: ETF04Detail,
      heading: "What is GLD?",
      list: [
        `   This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion`,
        `  It was started in 2004 and is run by an extremely credible asset
                manager, SPDR`,
        `  It has a 0.40% expense ratio, which is average`,
      ],
    },
  },
  {
    id: 9,
    image: ETF05,
    name: "Ticker: VUG",
    strategyName: "Big Growth Companies",
    riskScore: 2.6,
    returnPercentage: 8.31,
    type: "etf",
    underlying: "VUG",
    investorDetails: {
      image: ETF05Detail,
      heading: "What is VUG?",
      list: [
        ` Provides a convenient way to match the performance of many of
                the nation’s largest growth stocks.`,
        `     Follows a passively managed, full-replication approach with a
                0.04% expense ratio`,
      ],
    },
  },
  {
    id: 10,
    image: ETF06,
    name: "Ticker: IWM",
    strategyName: "Smaller Companies",
    riskScore: 4.45,
    returnPercentage: 7.47,
    type: "etf",
    underlying: "IWM",
    investorDetails: {
      image: ETF06Detail,
      heading: "What is IWM?",
      list: [
        `   This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion`,
        `   It was started in 2004 and is run by an extremely credible asset
                manager, SPDR`,
        ` It has a 0.40% expense ratio, which is average`,
      ],
    },
  },
  {
    id: 11,
    image: ETF07,
    name: "Ticker: VXUS",
    strategyName: "Global Economy",
    riskScore: 3.75,
    returnPercentage: 4.66,
    type: "etf",
    underlying: "VXUS",
    investorDetails: {
      image: ETF07Detail,
      heading: "What is VXUS?",
      list: [
        `    This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion`,
        `     It was started in 2004 and is run by an extremely credible asset
                manager, SPDR`,
        ` It has a 0.40% expense ratio, which is average`,
      ],
    },
  },
  {
    id: 12,
    image: ETF08,
    name: "Ticker: DIA",
    strategyName: "U.S Economy",
    riskScore: 3.6,
    returnPercentage: 8.48,
    type: "etf",
    underlying: "DIA",
    investorDetails: {
      image: ETF08Detail,
      heading: "What is DIA?",
      list: [
        `  This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion`,
        `  It was started in 2004 and is run by an extremely credible asset
                manager, SPDR`,
        ` It has a 0.40% expense ratio, which is average`,
      ],
    },
  },
];

const investorLabels = [
  {
    id: 1,
    label: "Your estimated investment",
    value: "$2,767,166",
    color: "#008037",
  },
  {
    id: 2,
    label: "Amount invested",
    value: "$460,000",
    color: "#50CFD7",
  },
  {
    id: 3,
    label: "Expected net gain",
    value: "$2,307,166",
    color: "#FFF",
  },
];

const brokers = [
  {
    id: 1,
    image: Alpaca,
    name: "Brokerage # ---5914",
    balance: "24,159.88",
    text: "You have $5,157.92 in cash to invest in this account.",
  },
];

export { stocksList, investorLabels, brokers };
